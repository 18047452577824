<template>
    <div>
        <div v-show="activeOffers"
            class="offer-bubble">% Aktion</div>
        <div class="offer-box"
            v-show="activeOffers">
            <!-- <span class="offer-text">Angebote</span>-->
            <ul class="list-style"
                v-html="getOfferText">
            </ul>
        </div>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'unit-offers',

    	data: function() {
    		return {
    			activeOffers: undefined
    		}
    	},

    	props: {
    		offers: Array,
    		unitOfTheDay: {
    			type: [String, Boolean],
    			default: false
    		},
    		className: {
    			type: String,
    			default: 'offer-box'
    		}
    	},
    	mounted: function() {

    	},
    	methods: {

    		check4ActiveOffers: function() {
    			//console.log('activeOffers::', this.activeOffers);
    			return this.activeOffers;
    		},

    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},

    		getPercentText: function(name, from, till, timeSpan, minStay, value, offerType) {
    			//console.log('name:', name, 'from:', from, 'till:', till, 'minStay:', minStay, 'value:', value);
    			var str = '<li><div>';

    			if (name) {
    				str += '<span>' + name + '</span>';
    			}
    			str += '<span>'

    			if (value) {
    				str += ' - ' + value / 100 + "% Rabatt"
    			}
    			if (from && !till) {
    				str += " ab " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			} else if (from && till) {
    				str += " vom " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			}

    			if (till) {
    				str += " bis " + DateEx.formatDate(till, undefined, VOFFICE.mlang);
    			}
    			if (offerType == "EARLYBIRD" && timeSpan) {
    				str += '<br><span class="small">- Bei Anreise in frühestens ' + timeSpan + ' Tagen</span>'
    			}

    			if (offerType == "LASTMINUTE" && timeSpan) {
    				str += '<br><span class="small">- Bei Anreise in spätestens ' + timeSpan + ' Tagen</span>'
    			}
    			if (minStay) {
    				str += '<br><span class="small">- Mindestaufenthalt ' + minStay + ' Nächte</span>'
    			}

    			str += '</span>'

    			str += '</div></li>';

    			//@todo timeSpan, minStay

    			return str;

    		},
    		getXYText: function(name, from, till, timeSpan, minStay, value) {
    			var str = '<li><div>';

    			if (name) {
    				str += '<span>' + name + ' - </span>';
    			}

    			str += '<span>'

    			if (from && !till) {
    				str += " ab " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			} else if (from && till) {
    				str += " vom " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			}

    			if (till) {
    				str += " bis " + DateEx.formatDate(till, undefined, VOFFICE.mlang);
    			}

    			if (minStay && value) {
    				str += '<br><span class="small">' + minStay + ' Nächte bleiben ' + (value / 100) + ' Nächte zahlen</span>'
    			}

    			str += '</span>'

    			str += '</div></li>';

    			return str;

    		},

    		getFlatText: function(name, from, till, timeSpan, minStay, value) {
    			var str = '<li><div>';

    			if (name) {
    				str += '<span>' + name + ' - </span>';
    			}

    			str += '<span>'

    			if (from && !till) {
    				str += " ab " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			} else if (from && till) {
    				str += " vom " + DateEx.formatDate(from, undefined, VOFFICE.mlang);
    			}

    			if (till) {
    				str += " bis " + DateEx.formatDate(till, undefined, VOFFICE.mlang);
    			}

    			str += '</span>'

    			str += '</li></div>';

    			return str;

    		},
    		checkEarlyBird: function(timeSpan, from, till, minStay) {
    			var today = DateEx.localDate(DateEx.getToday());
    			var diffDaysToTill;
    			if (till && timeSpan) {
    				var tillDay = DateEx.localDate(till.split('T')[0]);
    				if (minStay) {
    					tillDay = DateEx.plusDays(tillDay, -minStay);
    				}
    				diffDaysToTill = DateEx.getDays(tillDay, today);
    				if (diffDaysToTill < timeSpan) {
    					return false;
    				}
    			}
    			return true;
    		},
    		checkLastMinute: function(timeSpan, from, till, minStay) {
    			var today = DateEx.localDate(DateEx.getToday());
    			var diffDaysToFrom;
    			var diffDaysToTill;
    			if (from && timeSpan) {
    				var fromDay = DateEx.localDate(from.split('T')[0]);
    				diffDaysToFrom = DateEx.getDays(fromDay, today);
    				if (diffDaysToFrom > timeSpan) {
    					return false;
    				}
    			}
    			if (till && timeSpan) {
    				var tillDay = DateEx.localDate(till.split('T')[0]);
    				if (minStay) {
    					tillDay = DateEx.plusDays(tillDay, -minStay);
    				}
    				diffDaysToTill = DateEx.getDays(tillDay, today);
    				if (diffDaysToTill < timeSpan) {
    					return false;
    				}
    			}
    			return true;
    		}

    	},
    	computed: {

    		getOfferText: function() {

    			var offerText = '';
    			if (this.offers) {
    				var offers = this.offers;
    				console.log('Offers::', offers, 'UnitOfTheDay::', this.unitOfTheDay, 'Lang::', VOFFICE.mlang);
    				var voucherCode = this.getVoucherCode();
    				var lang = VOFFICE.mlang;
    				offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var offerName;

    				for (var i = 0; i < offers.length; i++) {
    					if (offers[i].name[lang]) {
    						offerName = offers[i].name[lang];
    					} else {
    						offerName = offers[i].name;
    					}


    					if (!offers[i].voucherCode || offers[i].voucherCode === voucherCode) {
    						// UnitOfTheDay
    						if (offers[i].type === "UNITOFDAY" && (this.unitOfTheDay || this.unitOfTheDaySearch)) {
    							//console.log('unitOfTheDay::::::', this.unitOfTheDay);
    							this.activeOffers = true;
    							if (offers[i].discountType === "PERCENT") {
    								offerText += this.getPercentText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value, offers[i].type);
    							} else if (offers[i].discountType === "XY") {
    								offerText += this.getXYText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    							} else if (offers[i].discountType === "FLAT") {
    								offerText += this.getFlatText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    							}
    						} else if (offers[i].type === "EARLYBIRD") {
    							if (this.checkEarlyBird(offers[i].timeSpan, offers[i].fromdate, offers[i].tilldate, offers[i].minStay)) {
    								this.activeOffers = true;
    								if (offers[i].discountType === "PERCENT") {
    									offerText += this.getPercentText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value, offers[i].type);
    								} else if (offers[i].discountType === "XY") {
    									offerText += this.getXYText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    								} else if (offers[i].discountType === "FLAT") {
    									offerText += this.getFlatText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    								}
    							}

    						} else if (offers[i].type === "LASTMINUTE") {
    							if (this.checkLastMinute(offers[i].timeSpan, offers[i].fromdate, offers[i].tilldate, offers[i].minStay)) {
    								this.activeOffers = true;
    								if (offers[i].discountType === "PERCENT") {
    									offerText += this.getPercentText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value, offers[i].type);
    								} else if (offers[i].discountType === "XY") {
    									offerText += this.getXYText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    								} else if (offers[i].discountType === "FLAT") {
    									offerText += this.getFlatText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    								}
    							}

    						} else if (offers[i].type !== "UNITOFDAY") {
    							if (offers[i].discountType === "PERCENT") {
    								this.activeOffers = true;
    								offerText += this.getPercentText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value, offers[i].type);
    							} else if (offers[i].discountType === "XY") {
    								this.activeOffers = true;
    								offerText += this.getXYText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    							} else if (offers[i].discountType === "FLAT") {
    								this.activeOffers = true;
    								offerText += this.getFlatText(offerName, offers[i].fromdate, offers[i].tilldate, offers[i].timeSpan, offers[i].minStay, offers[i].value);
    							}
    						}
    					}

    				}
    				return offerText;
    			}

    		}
    	}
    };
</script>