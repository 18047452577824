var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeOffers,
            expression: "activeOffers"
          }
        ],
        staticClass: "offer-bubble"
      },
      [_vm._v("% Aktion")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeOffers,
            expression: "activeOffers"
          }
        ],
        staticClass: "offer-box"
      },
      [
        _c("ul", {
          staticClass: "list-style",
          domProps: { innerHTML: _vm._s(_vm.getOfferText) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }